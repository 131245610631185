import { GetOverrides } from "./AnyCreativeReport";
import { ClientLayout } from "./Client";
import {
  CreativeReportingGroup,
  CreativeReportingGroupResponse,
} from "./CreativeReporting";
import { MetricsFilter, StatElement } from "./Metrics";
import {
  AttributionWindow,
  GroupBy,
  Provider,
  Sort,
  SortBy,
  Timeframe,
  ViewType,
} from "./shared";

export type LaunchReportResponse = {
  id: number;
  uuid: string;
  title: string | null;
  performanceTimeframe: Timeframe | null;
  performanceStartDate: Date | null;
  performanceEndDate: Date | null;
  launchTimeframe: Timeframe | null;
  launchStartDate: string | null;
  launchEndDate: string | null;
  createdBy: string;
  creatorId: number;
  createdAt: string;
  description: string | null;
  meta: boolean;
  tiktok: boolean;
  youtube: boolean;
  views: Array<LaunchReportViewResponse>;
  clientId: number;
  groupBy: GroupBy;
  folderId: number | null;
  fileSortOrder: number | null;
  workspaceId: number;
  selectedProvider: Provider | null;
  selectedViewType: ViewType | null;
  colorCodingLayout: ClientLayout | null;
  showCreationDateColumn: boolean;
  showTagColumn: boolean;
  showCompareValues: boolean;
  showBarOnCard: boolean;
};

export type LaunchReport = {
  id: number;
  uuid: string;
  title: string | null;
  performanceTimeframe: Timeframe | null;
  performanceStartDate: Date | null;
  performanceEndDate: Date | null;
  launchTimeframe: Timeframe | null;
  launchStartDate: Date | null;
  launchEndDate: Date | null;
  createdBy: string;
  creatorId: number;
  createdAt: string;
  description: string | null;
  meta: boolean;
  tiktok: boolean;
  youtube: boolean;
  views: Array<LaunchReportView>;
  clientId: number;
  groupBy: GroupBy;
  folderId: number | null;
  fileSortOrder: number | null;
  workspaceId: number;
  selectedProvider: Provider | null;
  selectedViewType: ViewType | null;
  colorCodingLayout: ClientLayout | null;
  showCreationDateColumn: boolean;
  showTagColumn: boolean;
  showCompareValues: boolean;
  showBarOnCard: boolean;
};

export type LaunchReportViewInfoResponse = {
  id: number;
  uuid: string;
  title: string;
  primaryMetric: string;
  secondaryMetrics: string[];
  globalFilter: Array<Array<MetricsFilter>>;
  winnerFilter: Array<Array<MetricsFilter>>;
  looserFilter: Array<Array<MetricsFilter>>;
  opportunitiesFilter: Array<Array<MetricsFilter>>;
  provider: Provider;
  createdAt: string;
  reportId: number;
  sort: Sort;
  description: string | null;
  attributionWindow: AttributionWindow;
  sortBy: SortBy;
};

export type LaunchReportViewInfo = {
  id: number;
  uuid: string;
  title: string;
  primaryMetric: string;
  secondaryMetrics: string[];
  globalFilter: Array<Array<MetricsFilter>>;
  winnerFilter: Array<Array<MetricsFilter>>;
  looserFilter: Array<Array<MetricsFilter>>;
  opportunitiesFilter: Array<Array<MetricsFilter>>;
  provider: Provider;
  createdAt: string;
  reportId: number;
  sort: Sort;
  description: string | null;
  attributionWindow: AttributionWindow;
  sortBy: SortBy;
};

export interface LaunchReportSubViewResponse {
  groups: Array<CreativeReportingGroupResponse>;
  accumulated: StatElement;
  total: number; // Total length of creatives
  pageNumber: number; // Page number of creatives
  groupIds: Array<number | string>; // List of all group ids (without pagination)
}

export interface LaunchReportSubView {
  groups: Array<CreativeReportingGroup>;
  accumulated: StatElement;
  total: number; // Total length of creatives
  pageNumber: number; // Page number of creatives
  groupIds: Array<number | string>; // List of all group ids (without pagination)
}

export type LaunchReportViewResponse = {
  info: LaunchReportViewInfoResponse;
  launched: LaunchReportSubViewResponse;
  winner: LaunchReportSubViewResponse;
  looser: LaunchReportSubViewResponse;
  opportunities: LaunchReportSubViewResponse;
};

export type LaunchReportView = {
  info: LaunchReportViewInfo;
  launched: LaunchReportSubView;
  winner: LaunchReportSubView;
  looser: LaunchReportSubView;
  opportunities: LaunchReportSubView;
};

export type LaunchReportCategory =
  | "launched"
  | "winner"
  | "looser"
  | "opportunities";

export const LaunchReportCategries: LaunchReportCategory[] = [
  "launched",
  "winner",
  "looser",
  "opportunities",
] as const;

export type GetGroupDto = {
  groupId: number | string;
  viewUuid: string;
  pageNumber: number;
  pageSize: number;
  groupBy: GroupBy;
  category: LaunchReportCategory;
  resolveAssets: boolean;
} & GetOverrides<string>;
