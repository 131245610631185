import { Buffer } from "buffer";
import { defineStore } from "pinia";
import { getBatchStartEnd } from "~/shared/utils";
import { GetOverrides } from "~/types/AnyCreativeReport";
import { ClientLayout, ClientTargetMetric } from "~/types/Client";
import { Creative, CreativeResponse } from "~/types/Creative";
import {
  CreativeReportingGroup,
  CreativeReportingGroupResponse,
} from "~/types/CreativeReporting";
import {
  GetGroupDto,
  LaunchReport,
  LaunchReportCategory,
  LaunchReportCategries,
  LaunchReportResponse,
  LaunchReportView,
  LaunchReportViewResponse,
} from "~/types/LaunchReport";
import { MetricsFilter, CustomMetricRule } from "~/types/Metrics";
import { ReportSnapshotResponse } from "~/types/ReportSnapshot";
import {
  AttributionWindow,
  GroupBy,
  Provider,
  Sort,
  SortBy,
  Timeframe,
  ViewType,
} from "~/types/shared";

export const useLaunchReportStore = defineStore({
  id: "launch-report-store",
  state: () => {
    return {
      reports: [],
      customConversionNames: {},
      customMetricRules: [],
      colorCodingLayout: ClientLayout.RELATIVE,
      targetMetrics: [],
      currency: "EUR",
    } as {
      reports: LaunchReport[];
      customConversionNames: Record<string, string>;
      customMetricRules: CustomMetricRule[];
      colorCodingLayout: ClientLayout;
      targetMetrics: ClientTargetMetric[];
      currency: string;
    };
  },
  actions: {
    async createReport(input: {
      title: string | null;
      clientId: number;
      folderId: number | null;
      description: string | undefined | null;
      meta: boolean;
      tiktok: boolean;
      youtube: boolean;
      performanceTimeframe?: Timeframe | null;
      launchTimeframe?: Timeframe | null;
      performanceStartDate?: string | null;
      launchStartDate?: string | null;
      performanceEndDate?: string | null;
      launchEndDate?: string | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { report: LaunchReportResponse };
      }>("/launch-report/create", {
        method: "POST",
        body: {
          title: input.title === "" ? null : input.title,
          description: input.description === "" ? null : input.description,
          clientId: input.clientId,
          meta: input.meta,
          tiktok: input.tiktok,
          folderId: input.folderId,
          youtube: input.youtube,
          performanceTimeframe: input.performanceTimeframe,
          performanceStartDate: input.performanceStartDate,
          performanceEndDate: input.performanceEndDate,
          launchTimeframe: input.launchTimeframe,
          launchStartDate: input.launchStartDate,
          launchEndDate: input.launchEndDate,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedLaunchReports } = useLaunchReport();
        const report = getMappedLaunchReports([data.value.data.report])[0];
        this.reports.push(report);
        return { id: report.id, uuid: report.uuid };
      }
      return null;
    },

    async duplicateReport(input: {
      reportId: number;
      clientId: number;
      folderId: number | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { report: LaunchReportResponse };
      }>(`launch-report/duplicate`, {
        method: "POST",
        body: {
          clientId: input.clientId,
          reportId: input.reportId,
          folderId: input.folderId,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedLaunchReports } = useLaunchReport();
        const report = getMappedLaunchReports([data.value.data.report])[0];
        this.reports.push(report);
        return { id: report.id, uuid: report.uuid };
      }
      return null;
    },

    async updateReportMetadata(input: {
      reportId: number;
      title?: string | undefined | null;
      description?: string | undefined | null;
      selectedViewType?: ViewType | undefined | null;
      colorCodingLayout?: ClientLayout | undefined | null;
      showCreationDateColumn?: boolean | undefined | null;
      showTagColumn?: boolean | undefined | null;
      showCompareValues?: boolean | undefined | null;
      showBarOnCard?: boolean | undefined | null;
    }) {
      const { error } = await useDatAdsApiFetch(
        `launch-report/report-metadata/${input.reportId}`,
        {
          method: "PATCH",
          body: {
            title: input.title === "" ? null : input.title,
            description: input.description === "" ? null : input.description,
            selectedViewType: input.selectedViewType,
            colorCodingLayout: input.colorCodingLayout,
            showCreationDateColumn: input.showCreationDateColumn,
            showTagColumn: input.showTagColumn,
            showCompareValues: input.showCompareValues,
            showBarOnCard: input.showBarOnCard,
          },
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const reportIndex = this.reports.findIndex(
        (report) => report.id === input.reportId,
      );
      if (reportIndex !== -1) {
        this.reports.splice(reportIndex, 1, {
          ...this.reports[reportIndex],
          title: input.title ?? this.reports[reportIndex].title,
          description:
            input.description ?? this.reports[reportIndex].description,
          selectedViewType:
            input.selectedViewType ??
            this.reports[reportIndex].selectedViewType,
          colorCodingLayout:
            input.colorCodingLayout ??
            this.reports[reportIndex].colorCodingLayout,
          showCreationDateColumn:
            input.showCreationDateColumn ??
            this.reports[reportIndex].showCreationDateColumn,
          showTagColumn:
            input.showTagColumn ?? this.reports[reportIndex].showTagColumn,
          showCompareValues:
            input.showCompareValues ??
            this.reports[reportIndex].showCompareValues,
          showBarOnCard:
            input.showBarOnCard ?? this.reports[reportIndex].showBarOnCard,
        });
      }
    },

    async updateReport(input: {
      reportId: number;
      performanceTimeframe?: Timeframe | undefined | null;
      launchTimeframe?: Timeframe | undefined | null;
      performanceStartDate?: string | undefined | null;
      launchStartDate?: string | undefined | null;
      performanceEndDate?: string | undefined | null;
      launchEndDate?: string | undefined | null;
      groupBy?: GroupBy | undefined | null;
      returnProvider: Provider;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          report: LaunchReportResponse;
          customConversionNames: Record<string, string>;
          customMetricRules: CustomMetricRule[];
        };
      }>(`launch-report/${input.reportId}`, {
        method: "PATCH",
        body: {
          performanceTimeframe: input.performanceTimeframe ?? undefined,
          performanceStartDate: input.performanceStartDate ?? undefined,
          performanceEndDate: input.performanceEndDate ?? undefined,
          launchTimeframe: input.launchTimeframe ?? undefined,
          launchStartDate: input.launchStartDate ?? undefined,
          launchEndDate: input.launchEndDate ?? undefined,
          groupBy: input.groupBy ?? undefined,
          returnProvider: input.returnProvider,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.customMetricRules = data.value.data.customMetricRules;
        const { getMappedLaunchReports } = useLaunchReport();
        const report = getMappedLaunchReports([data.value.data.report])[0];
        this.setReport(report);
        return report;
      }
      return null;
    },

    async createSnapshot(dto: { reportId: number }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          snapshot: ReportSnapshotResponse<LaunchReportResponse>;
        };
      }>("/launch-report/snapshot", {
        method: "POST",
        body: { reportId: dto.reportId },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedLaunchReportSnapshpts } = useLaunchReport();
        const snapshot = getMappedLaunchReportSnapshpts([
          data.value.data.snapshot,
        ])[0];
        return snapshot;
      }
      return null;
    },

    async updateView(input: {
      viewId: number;
      pageNumber: number;
      pageSize: number;
      groupBy: GroupBy; // Required for mapping response
      primaryMetric?: string;
      secondaryMetrics?: Array<string>;
      globalFilter?: Array<Array<MetricsFilter>> | null;
      winnerFilter?: Array<Array<MetricsFilter>> | null;
      looserFilter?: Array<Array<MetricsFilter>> | null;
      opportunitiesFilter?: Array<Array<MetricsFilter>> | null;
      sort?: Sort;
      attributionWindow?: AttributionWindow | null;
      sortBy?: SortBy;
      category: LaunchReportCategory;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { view: LaunchReportViewResponse };
      }>(`launch-report/view/${input.viewId}`, {
        method: "PATCH",
        body: {
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
          primaryMetric: input.primaryMetric ?? undefined,
          secondaryMetrics: input.secondaryMetrics ?? undefined,
          globalFilter: input.globalFilter,
          winnerFilter: input.winnerFilter,
          looserFilter: input.looserFilter,
          opportunitiesFilter: input.opportunitiesFilter,
          sort: input.sort ?? undefined,
          sortBy: input.sortBy ?? undefined,
          attributionWindow: input.attributionWindow,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedLaunchReportViews } = useLaunchReport();
        const view = getMappedLaunchReportViews(
          [data.value.data.view],
          input.groupBy,
        )[0];
        const reportIdx = this.reports.findIndex(
          (report) => report.id === view.info.reportId,
        );
        this.setView({
          view,
          reportIdx,
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
        });
        return view;
      }
      return null;
    },

    async getReport(
      input: (
        | {
            reportUuid: string;
          }
        | {
            reportId: number;
          }
      ) & {
        provider?: Provider;
        groupBy?: GroupBy | undefined | null;
        resolveAssets?: boolean;
      } & GetOverrides<string>,
    ) {
      const { getOverridesQuery } = useQuery();
      const id = "reportUuid" in input ? input.reportUuid : input.reportId;
      const path =
        "reportUuid" in input
          ? `launch-report/report/${id}`
          : `launch-report/report/id/${id}`;
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          report: LaunchReportResponse;
          customConversionNames: Record<string, string>;
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          customMetricRules: CustomMetricRule[];
          currency: string;
        };
      }>(path, {
        query: {
          ...getOverridesQuery(input),
          provider: input.provider,
          groupBy: input.groupBy,
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        const errorMesage = useErrorHandler(error.value);
        return errorMesage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedLaunchReports } = useLaunchReport();
        const report = getMappedLaunchReports([data.value.data.report])[0];
        this.setReport(report);
        return report;
      }
      return null;
    },

    async getReportSnapshot(dto: { reportUuid: string }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          snapshot: ReportSnapshotResponse<LaunchReportResponse>;
          customConversionNames: Record<string, string>;
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          customMetricRules: CustomMetricRule[];
          currency: string;
        };
      }>(`launch-report/report/${dto.reportUuid}/snapshot`);
      if (error.value) {
        const errorMesage = useErrorHandler(error.value);
        return errorMesage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedLaunchReportSnapshpts } = useLaunchReport();
        const snapshot = getMappedLaunchReportSnapshpts([
          data.value.data.snapshot,
        ])[0];
        return snapshot;
      }
      return null;
    },

    async getView(
      input: {
        viewUuid: string;
        pageNumber: number;
        pageSize: number;
        groupBy: GroupBy;
        resolveAssets: boolean;
      } & GetOverrides<string>,
    ) {
      const { getOverridesQuery } = useQuery();
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          view: LaunchReportViewResponse;
          customConversionNames: Record<string, string>;
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          customMetricRules: CustomMetricRule[];
          currency: string;
        };
      }>(`launch-report/view/${input.viewUuid}`, {
        query: {
          ...getOverridesQuery(input),
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
          groupBy: input.groupBy,
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedLaunchReportViews } = useLaunchReport();
        const view = getMappedLaunchReportViews(
          [data.value.data.view],
          input.groupBy,
        )[0];
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((v) => v.info.uuid === input.viewUuid),
        );
        this.setView({
          view,
          reportIdx,
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
        });
      }
      return null;
    },

    async getGroup(input: GetGroupDto) {
      const { getOverridesQuery } = useQuery();
      const encodedGroupId = encodeURIComponent(
        Buffer.from(input.groupId.toString()).toString("base64"),
      );
      const { data, error } = await useDatAdsApiFetch<{
        data: { group: CreativeReportingGroupResponse };
      }>(`launch-report/group`, {
        query: {
          ...getOverridesQuery(input),
          viewUuid: input.viewUuid,
          groupId: encodedGroupId,
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
          groupBy: input.groupBy,
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        useErrorHandler(error.value);
        return null;
      }
      if (data.value) {
        const { getMappedCreativeReportingGroups } = useCreativeReporting();
        const group = getMappedCreativeReportingGroups(
          [data.value.data.group],
          input.groupBy,
        )[0];
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((v) => v.info.uuid === input.viewUuid),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.uuid === input.viewUuid,
        );
        let groupIdx = this.reports[reportIdx].views[viewIdx][
          input.category
        ].groups.findIndex((g) => g.info.id === group.info.id);
        if (groupIdx === -1) {
          groupIdx =
            this.reports[reportIdx].views[viewIdx][input.category].groups.push(
              group,
            );
          return this.reports[reportIdx].views[viewIdx][input.category].groups[
            groupIdx
          ];
        }
        if (input.pageNumber === 1) {
          // From the backend we are getting unsorted list of creatives
          // If we are at page 0, we thus need to reset the list.
          // Otherwise we would populate the creatives unsorted
          this.reports[reportIdx].views[viewIdx][input.category].groups[
            groupIdx
          ].creatives = [];
        }
        group.creatives.forEach((creative, idx) =>
          this.setCreative({
            creative,
            reportIdx,
            viewIdx,
            groupIdx,
            pageNumber: input.pageNumber,
            pageSize: input.pageSize,
            batchIdx: idx,
            category: input.category,
          }),
        );
        if (groupIdx !== -1) {
          this.reports[reportIdx].views[viewIdx][input.category].groups.splice(
            groupIdx,
            1,
            {
              ...group,
              // Update all properties except creatives (and derived properties)
              // Otherwise the overall group appearance might change (e.g. images of group)
              info: {
                ...this.reports[reportIdx].views[viewIdx][input.category]
                  .groups[groupIdx].info,
                images:
                  this.reports[reportIdx].views[viewIdx][input.category].groups[
                    groupIdx
                  ].info.images,
                videos:
                  this.reports[reportIdx].views[viewIdx][input.category].groups[
                    groupIdx
                  ].info.videos,
                image:
                  this.reports[reportIdx].views[viewIdx][input.category].groups[
                    groupIdx
                  ].info.image,
                video:
                  this.reports[reportIdx].views[viewIdx][input.category].groups[
                    groupIdx
                  ].info.video,
              },
              creatives:
                this.reports[reportIdx].views[viewIdx][input.category].groups[
                  groupIdx
                ].creatives,
            },
          );
        }
        return this.reports[reportIdx].views[viewIdx][input.category].groups[
          groupIdx
        ];
      }
      return null;
    },

    async listReports(clientId: number) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { reports: LaunchReportResponse[] };
      }>(`launch-report/client/${clientId}`);
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedLaunchReports } = useLaunchReport();
        const reports = getMappedLaunchReports(data.value.data.reports);
        this.setReports(reports);
      }
      return this.reports.filter((report) => report.clientId === clientId);
    },

    async listCreativesOfGroup(input: GetGroupDto) {
      const { getOverridesQuery } = useQuery();
      const encodedGroupId = encodeURIComponent(
        Buffer.from(input.groupId.toString()).toString("base64"),
      );
      const { data, error } = await useDatAdsApiFetch<{
        data: { creatives: CreativeResponse[] };
      }>(`launch-report/group/list-creatives`, {
        query: {
          ...getOverridesQuery(input),
          viewUuid: input.viewUuid,
          groupId: encodedGroupId,
          pageNumber: input.pageNumber,
          pageSize: input.pageSize,
          groupBy: input.groupBy,
        },
      });
      if (error.value) {
        useErrorHandler(error.value);
        return null;
      }
      if (data.value) {
        const { getMappedCreatives } = useCreatives();
        const creatives = getMappedCreatives(data.value.data.creatives);
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((v) => v.info.uuid === input.viewUuid),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.uuid === input.viewUuid,
        );
        const groupIdx = this.reports[reportIdx].views[viewIdx][
          input.category
        ].groups.findIndex((group) => group.info.id === input.groupId);
        if (input.pageNumber === 1) {
          // From the backend we are getting unsorted list of creatives
          // If we are at page 0, we thus need to reset the list.
          // Otherwise we would populate the creatives unsorted
          this.reports[reportIdx].views[viewIdx][input.category].groups[
            groupIdx
          ].creatives = [];
        }
        creatives.forEach((creative, idx) =>
          this.setCreative({
            creative,
            reportIdx,
            viewIdx,
            groupIdx,
            pageNumber: input.pageNumber,
            pageSize: input.pageSize,
            batchIdx: idx,
            category: input.category,
          }),
        );
        return creatives;
      }
      return [];
    },

    async resolveGroupAssets(dto: {
      groups: CreativeReportingGroup[];
      slice: number | undefined;
      groupBy: GroupBy;
    }) {
      const { resolveGroupAssets } = useAssets();
      const groupsWithAssets = await resolveGroupAssets(dto);
      const { getMappedCreativeReportingGroups } = useCreativeReporting();
      const mappedGroups = getMappedCreativeReportingGroups(
        groupsWithAssets,
        dto.groupBy,
      );
      for (const report of this.reports) {
        for (const view of report.views) {
          for (const category of LaunchReportCategries) {
            view[category].groups = view[category].groups.map((group) => {
              const groupWithAssets = mappedGroups.find(
                (g) => g.info.id === group.info.id,
              );
              return groupWithAssets ?? group;
            });
          }
        }
      }
    },

    async deleteReport(reportId: number) {
      const { error } = await useDatAdsApiFetch(`launch-report/${reportId}`, {
        method: "DELETE",
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const index = this.reports.findIndex((report) => report.id === reportId);
      if (index !== -1) {
        this.reports.splice(index, 1);
      }
      return null;
    },

    setReports(reports: LaunchReport[]) {
      // Remove all reports not in returned list
      this.reports = this.reports.filter((report) =>
        reports.some((r) => r.id === report.id),
      );
      // Add or update reports
      reports.forEach((r) => this.setReport(r));
    },

    setReport(report: LaunchReport) {
      const index = this.reports.findIndex((r) => r.id === report.id);
      if (index === -1) {
        this.reports.push(report);
      } else {
        report.views.forEach((view) =>
          this.setView({
            view,
            reportIdx: index,
          }),
        );
        // Keep views
        this.reports.splice(index, 1, {
          ...report,
          views: this.reports[index].views,
        });
      }
    },

    setView(
      dto:
        | {
            view: LaunchReportView;
            reportIdx: number;
          }
        | {
            view: LaunchReportView;
            reportIdx: number;
            pageNumber: number;
            pageSize: number;
          },
    ) {
      const views = this.reports[dto.reportIdx].views;
      const index = views.findIndex((v) => v.info.id === dto.view.info.id);
      if (index === -1) {
        views.push(dto.view);
      } else {
        views.splice(index, 1, { ...dto.view });
        if ("pageNumber" in dto && "pageSize" in dto) {
          for (const category of LaunchReportCategries) {
            const groups = [...dto.view[category].groups];
            // Groups might be at incorrect position in array
            // E.g. pagesize 8, pagenumber 2, then 8 groups are fetched, but at indices 0-7
            views[index][category].groups = [];
            groups.forEach((group, idx) =>
              this.setGroup({
                group,
                reportIdx: dto.reportIdx,
                viewIdx: index,
                pageNumber: dto.pageNumber,
                pageSize: dto.pageSize,
                batchIdx: idx,
                category,
              }),
            );
          }
        }
      }
    },

    setGroup(input: {
      group: CreativeReportingGroup;
      reportIdx: number;
      viewIdx: number;
      pageNumber: number;
      pageSize: number;
      batchIdx: number; // Index of group in batch
      category: LaunchReportCategory;
    }) {
      const { getEmptyGroup, isEmptyGroup } = useCreativeReporting();

      const groups =
        this.reports[input.reportIdx].views[input.viewIdx][input.category]
          .groups;
      const { start, end } = getBatchStartEnd({
        pageNumber: input.pageNumber,
        pageSize: input.pageSize,
      });
      const groupsBatch = groups.slice(start, end);

      // If the user clicks on a page in the middle of the list
      // we need to fill the previous pages with empty groups
      // Those get replaced by the actual groups when we fetch them
      if (groups.length < start) {
        for (let i = groups.length; i < start; i++) {
          groups.push(
            getEmptyGroup({
              idx: i,
              groupBy: input.group.info.groupBy,
              reportId: input.group.info.reportId,
              viewId: input.group.info.viewId,
            }),
          );
        }
      }

      // Replace empty group with actual group
      if (
        groupsBatch[input.batchIdx] != null &&
        isEmptyGroup(groupsBatch[input.batchIdx])
      ) {
        groups.splice(start + input.batchIdx, 1, input.group);
        return;
      }

      // Add group to groups
      groups.splice(start + input.batchIdx, 0, input.group);
    },

    setCreative(input: {
      creative: Creative;
      reportIdx: number;
      viewIdx: number;
      groupIdx: number;
      pageNumber: number;
      pageSize: number;
      batchIdx: number; // Index of creative in batch
      category: LaunchReportCategory;
    }) {
      const { getEmptyCreative, isEmptyCreative } = useCreatives();

      const creatives =
        this.reports[input.reportIdx].views[input.viewIdx][input.category]
          .groups[input.groupIdx].creatives;
      const { start, end } = getBatchStartEnd({
        pageNumber: input.pageNumber,
        pageSize: input.pageSize,
      });
      const creativesBatch = creatives.slice(start, end);

      // If the user clicks on a page in the middle of the list
      // we need to fill the previous pages with empty creatives
      // Those get replaced by the actual creatives when we fetch them
      if (creatives.length < start) {
        for (let i = creatives.length; i < start; i++) {
          creatives.push(
            getEmptyCreative({
              idx: i,
            }),
          );
        }
      }

      // Replace empty creative with actual creative
      if (
        creativesBatch[input.batchIdx] != null &&
        isEmptyCreative(creativesBatch[input.batchIdx])
      ) {
        creatives.splice(start + input.batchIdx, 1, input.creative);
        return;
      }

      // Add creative to creatives
      creatives.splice(start + input.batchIdx, 0, input.creative);
    },

    addCustomMetricRule(rule: CustomMetricRule) {
      this.customMetricRules.push(rule);
    },

    async updateCustomMetricRule(dto: {
      newRule: CustomMetricRule;
      oldRule: CustomMetricRule;
      viewId: number;
    }) {
      const { newRule, oldRule, viewId } = dto;
      const index = this.customMetricRules.findIndex(
        (r) => r.customMetricRuleId === newRule.customMetricRuleId,
      );
      if (index < 0) {
        return;
      }
      this.customMetricRules.splice(index, 1, { ...newRule });
      const isRenamed = newRule.metricName !== oldRule.metricName;
      if (!isRenamed) {
        return;
      }
      await this.renameCustomMetricRule({
        newName: newRule.metricName,
        oldName: oldRule.metricName,
        viewId,
      });
    },

    async renameCustomMetricRule(dto: {
      newName: string;
      oldName: string;
      viewId: number;
    }) {
      const { newName, oldName, viewId } = dto;
      this.customMetricRules.forEach((rule) => {
        if (rule.metricName === oldName) {
          rule.metricName = newName;
        }
      });
      const view = this.reports
        .flatMap((report) => report.views)
        .find((view) => view.info.id === viewId);
      if (view == null) {
        return;
      }
      const { updateQuery } = useQuery();
      if (view.info.primaryMetric === oldName) {
        view.info.primaryMetric = newName;
        await updateQuery({ primaryMetric: newName });
      }
      view.info.secondaryMetrics = view.info.secondaryMetrics.map((m) =>
        m === oldName ? newName : m,
      );
      if (view.info.globalFilter != null) {
        const { renameFiltersWithCustomMetric } = useFilter();
        view.info.globalFilter = renameFiltersWithCustomMetric({
          oldMetricName: oldName,
          newMetricName: newName,
          filter: view.info.globalFilter,
        });
        await updateQuery({
          globalFilter: JSON.stringify(view.info.globalFilter),
        });
      }
      if (view.info.looserFilter != null) {
        const { renameFiltersWithCustomMetric } = useFilter();
        view.info.looserFilter = renameFiltersWithCustomMetric({
          oldMetricName: oldName,
          newMetricName: newName,
          filter: view.info.looserFilter,
        });
        await updateQuery({
          looserFilter: JSON.stringify(view.info.looserFilter),
        });
      }
      if (view.info.winnerFilter != null) {
        const { renameFiltersWithCustomMetric } = useFilter();
        view.info.winnerFilter = renameFiltersWithCustomMetric({
          oldMetricName: oldName,
          newMetricName: newName,
          filter: view.info.winnerFilter,
        });
        await updateQuery({
          winnerFilter: JSON.stringify(view.info.winnerFilter),
        });
      }
      if (view.info.opportunitiesFilter != null) {
        const { renameFiltersWithCustomMetric } = useFilter();
        view.info.opportunitiesFilter = renameFiltersWithCustomMetric({
          oldMetricName: oldName,
          newMetricName: newName,
          filter: view.info.opportunitiesFilter,
        });
        await updateQuery({
          opportunitiesFilter: JSON.stringify(view.info.opportunitiesFilter),
        });
      }
    },

    async deleteCustomMetricRule(ruleId: number, viewId: number) {
      const index = this.customMetricRules.findIndex(
        (r) => r.customMetricRuleId === ruleId,
      );
      if (index < 0) {
        return;
      }
      const rule = { ...this.customMetricRules[index] };
      this.customMetricRules.splice(index, 1);
      const view = this.reports
        .flatMap((report) => report.views)
        .find((view) => view.info.id === viewId);
      if (view == null) {
        return;
      }
      const { updateQuery } = useQuery();
      const secondaryIdx = view.info.secondaryMetrics.findIndex(
        (m) => m === rule.metricName,
      );
      if (secondaryIdx >= 0) {
        view.info.secondaryMetrics.splice(secondaryIdx, 1);
        await updateQuery({
          secondaryMetrics: JSON.stringify(view.info.secondaryMetrics),
        });
      }
      if (view.info.globalFilter != null) {
        const { removeFiltersWithCustomMetric } = useFilter();
        view.info.globalFilter = removeFiltersWithCustomMetric({
          metricName: rule.metricName,
          filter: view.info.globalFilter,
        });
        await updateQuery({
          globalFilter: JSON.stringify(view.info.globalFilter),
        });
      }
      if (view.info.looserFilter != null) {
        const { removeFiltersWithCustomMetric } = useFilter();
        view.info.looserFilter = removeFiltersWithCustomMetric({
          metricName: rule.metricName,
          filter: view.info.looserFilter,
        });
        await updateQuery({
          looserFilter: JSON.stringify(view.info.looserFilter),
        });
      }
      if (view.info.winnerFilter != null) {
        const { removeFiltersWithCustomMetric } = useFilter();
        view.info.winnerFilter = removeFiltersWithCustomMetric({
          metricName: rule.metricName,
          filter: view.info.winnerFilter,
        });
        await updateQuery({
          winnerFilter: JSON.stringify(view.info.winnerFilter),
        });
      }
      if (view.info.opportunitiesFilter != null) {
        const { removeFiltersWithCustomMetric } = useFilter();
        view.info.opportunitiesFilter = removeFiltersWithCustomMetric({
          metricName: rule.metricName,
          filter: view.info.opportunitiesFilter,
        });
        await updateQuery({
          opportunitiesFilter: JSON.stringify(view.info.opportunitiesFilter),
        });
      }
    },
  },
  getters: {
    getReportsOfClient: (state) => (clientId: number) => {
      return state.reports.filter((report) => report.clientId === clientId);
    },

    getReportById: (state) => (reportId: number) => {
      return state.reports.find((report) => report.id === reportId) ?? null;
    },

    getReportByUuid: (state) => (reportUuid: string) => {
      return state.reports.find((report) => report.uuid === reportUuid) ?? null;
    },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useLaunchReportStore, import.meta.hot),
  );
}
